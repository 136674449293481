<template>
  <div>
    <h4 class="title has-text-centered is-4">Search</h4>

    <form @submit="handleSubmit">
      <b-field grouped>
        <b-field label="User Name" custom-class="is-small">
          <b-input v-model="formData.name" size="is-small"></b-input>
        </b-field>

        <b-field label="User Email" custom-class="is-small">
          <b-input v-model="formData.email" size="is-small"></b-input>
        </b-field>

        <b-field label="Min Date" custom-class="is-small">
          <b-input v-model="formData.min_date" size="is-small"></b-input>
        </b-field>

        <b-field label="Max Date" custom-class="is-small">
          <b-input v-model="formData.max_date" size="is-small"></b-input>
        </b-field>

        <b-field label="Origin" custom-class="is-small">
          <b-select v-model="formData.origin" size="is-small">
            <option></option>
            <option value="patreon_salary">Patreon Salary</option>
            <option value="furniture">Furniture Sale</option>
            <option value="daily_earn">Daily Earn</option>
            <option value="feat_award">Feat Award</option>
            <option value="wheel_award">Wheel Award</option>
          </b-select>
        </b-field>

        <b-field label="Type" custom-class="is-small">
          <b-select v-model="formData.type" size="is-small">
            <option></option>
            <option value="1">Earn</option>
            <option value="0">Spend</option>
          </b-select>
        </b-field>
      </b-field>

      <b-button type="is-info" icon-left="search" native-type="submit" size="is-small">Search</b-button>
    </form>

  </div>
</template>

<script>
export default {
  name: 'transactions-search-form',
  data() {
    return {
      errors: [],
      formData: {
        ...this.$route.query,
      },
      loadingForm: false,
      sent: false,
    };
  },
  methods: {
    handleSubmit(event) {
      event.preventDefault();

      this.errors = [];
      this.loadingForm = true;

      const params = {
        ...this.formData,
        page: 1,
      };

      const queryString = Object.keys(params).map((key) => `${key}=${params[key] || ''}`).join('&');

      this.$router.push({ path: `/transactions?${queryString}` });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
