<template>
  <section class="section">
    <transactions-search-form></transactions-search-form>

    <h4 class="title has-text-centered is-4">Transactions</h4>

    <earnings-list :earnings="transactions.data" :loading="loadingTransactions"></earnings-list>

    <b-pagination
      class="mt-4"
      v-model="transactions.current_page"
      :total="transactions.total"
      :per-page="transactions.per_page"
      range-before="3"
      range-after="3"
      order="is-centered"
      icon-pack="fas"
      @change="handlePageChange"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page">
    </b-pagination>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import EarningsList from '../components/Transactions/EarningsList.vue';
import TransactionsSearchForm from '../components/Transactions/TransactionsSearchForm.vue';

export default {
  name: 'Transactions',
  components: { TransactionsSearchForm, EarningsList },
  computed: {
    ...mapState('transaction', ['loadingTransactions', 'transactions']),
  },
  mounted() {
    this.loadTransactions();
  },
  watch: {
    // eslint-disable-next-line func-names
    '$route.query': function () {
      this.loadTransactions();
    },
  },
  methods: {
    loadTransactions() {
      this.$store.dispatch('transaction/loadTransactions', this.$route.query);
    },
    handlePageChange(pageNum) {
      const params = {
        ...this.$route.query,
        page: pageNum,
      };
      const queryString = Object.keys(params).map((key) => `${key}=${params[key] || ''}`).join('&');

      this.$router.push({ path: `transactions?${queryString}` });
    },
  },
};
</script>

<style>
.user-table {
  font-size: 12px;
}
.clickable-user {
  cursor: pointer;
  text-decoration: underline;
}
</style>
